import { useEffect, useRef, useState } from "react"
import vendaService from "../../services/vendaService"
import Pagination from "../auxiliares/pagination"
import { Link } from "react-router-dom"

export default function VendaLista() {
    const [vendas,set_vendas] = useState([])
    const [total,set_total] = useState(0)
    const [pagina,set_pagina] = useState(1)
    const [paginas,set_paginas] = useState([1])
    const empRef = useRef()
    
    useEffect(
        ()=>{
        vendaService.lista(1)
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_vendas(data[0]);
            set_paginas(Math.ceil(data[1]/20))
        })
        .catch((err) => console.log(err))
    },[])

    function passarPagina (pagina,tipo){
        buscaPorPagina(pagina)
    }

    function buscaPorPagina(pagina){
        vendaService.lista(pagina)
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_vendas(data[0]);
            set_pagina(pagina)
        })
        .catch((err) => console.log(err))
    }


    return(
    <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 " >
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 bg-white" >
                <header>
                <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">Vendas</h2>

                <p className="mt-4 max-w-md text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque praesentium cumque iure
                    dicta incidunt est ipsam, officia dolor fugit natus?
                </p>
                </header>

                <div className="mt-8 block lg:hidden">
                <button
                    className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600"
                >
                    <span className="text-sm font-medium"> Filters & Sorting </span>

                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-4 w-4 rtl:rotate-180"
                    >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                        <thead className="ltr:text-left rtl:text-right">
                        <tr>
                            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Empreendimento</th>
                            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Unidade N°</th>
                            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Valor </th>
                            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Sinal</th>
                            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Corretor</th>
                            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Status</th>
                            <th className="px-4 py-2"></th>
                        </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 " >
                            {vendas.map((venda,index)=>{
                                return <tr className="ltr:text-left rtl:text-right">
                                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{venda.unidade?.empreendimento?.nome}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{venda?.unidade?.numero}</td>
                                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">{venda.valor}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{venda.valor_sinal}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{venda.corretor?.nome}</td>
                                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{venda.status?.descricao}</td>
                                    <td className="whitespace-nowrap px-4 py-2">
                                    <Link to={`/venda/${venda.id}`}>
                                        <a
                                            href="#"
                                            className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                                        >
                                            Detalhe
                                        </a>
                                    </Link>
                                    </td>
                                </tr>
                            })}


                        </tbody>
                    </table>
                </div>

                <Pagination param={{paginas:paginas,pagina:pagina}} parentmethod={passarPagina}></Pagination>
            </div>
        </div>
        </section>
    )
}