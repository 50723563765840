import { useState } from "react"
import LabelForm from "../auxiliares/labelForm"
import LabelFormEdit from "../auxiliares/labelFormEdit"
import { useParams } from "react-router-dom"
import empreendimentoService from "../../services/empreendimentoService"
import { useEffect } from "react"
import UnidadeDetalhe from "../unidade/unidadeDetalhe"

export default function EmpreendimentoDetalhe (){
    let {id} = useParams()
    const [cliente,set_cliente] = useState({
        nome:'',
        sobrenome: '', 
        telefone: '', 
        email: '', 
        endereco: '', 
        bairro: '', 
        cidade: '', 
        estado: '', 
        profissao: '',
    })
    const [empreendimento,set_empreendimento] = useState(
        {
            id: 0,
            nome: "",
            endereco: "",
            bairro: "",
            cidade: "",
            estado: "",
            unidades:[]
        }
    )
    
    useEffect(
        ()=>{
        empreendimentoService.detalhe(id)
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_empreendimento(data);
        })
        .catch((err) => console.log(err))
    },[])



    function onChangeEmpreendimento(e){
        const {id,value} = e.target
        set_empreendimento({...empreendimento,[id]:value})
    }

    return(
        <section className="bg-gray-100">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8" >
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 ">
                    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                        <form action="" className="space-y-4">
                            
                            <h2 class="text-2xl font-bold sm:text-3xl">Emp - {id}</h2>
                            <div>

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Nome',type:'text',value:empreendimento.nome,id:'nome'}} 
                                    parentmethod={onChangeEmpreendimento}
                                    disabled={true}/>
                                    
                                </div>

                            </div>
                            <div>
                                <div>
                                    <LabelFormEdit
                                    param={{label:'Bairro',type:'text',value:empreendimento.bairro,id:'bairro'}} 
                                    parentmethod={onChangeEmpreendimento}
                                    disabled={true}/>
                                    </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelFormEdit 
                                    param={{label:'Cidade',type:'text',value:empreendimento.cidade,id:'cidade'}} 
                                    parentmethod={onChangeEmpreendimento}
                                    disabled={true}
                                    />
                                   
                                </div>

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Estado',type:'text',value:empreendimento.estado,id:'estado'}} 
                                    parentmethod={onChangeEmpreendimento}
                                    disabled={true}/>
                                      
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
                <br/>
                <div className="grid grid-cols-3 gap-6 " >  
                    {empreendimento.unidades.map(unidade=><UnidadeDetalhe parentUnidade={unidade}/>)}
                </div>
            </div>
        </section>
    )
}