import { Link, Outlet } from "react-router-dom";
import SideBar from "../components/sidebar";


export default function Root() {
    return (
    <>
        <h1 className="text-3xl font-bold underline">AVMC</h1>
        <div className="flex">
            <SideBar></SideBar>
            <div className="flex-auto">
                <Outlet className='flex-auto'></Outlet>            
            </div>
            
        </div>
        <div id="detail">

        </div>
    </>
    );
  }