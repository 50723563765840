import axios from 'axios';
import config from '../config'
//import authHeader from './auth-header.service.js';

const API_URL = config.OriginPath+'/corretor/';

class CorretorService {
  detalhe(id) {
    return axios.get(API_URL + `${id}`,{});
  }

  lista() {
    return axios.get(API_URL+``,{});
  }

  cadastrar(corretor) {
    return axios.post(API_URL ,corretor);
  }
}

export default new CorretorService();