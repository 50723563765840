export default function LabelFormEdit({param,parentmethod,disabled}){
    const bg =  disabled?'bg-transparent':'bg-gray-200'
    
    return(
        <label
        for={param.id}
        class="relative block overflow-hidden border-b border-gray-200 bg-gray pt-3 focus-within:border-blue-600"
        >
            <input
                className={`peer h-8 w-full border-none ${bg} p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm `}
                placeholder={param.label}
                type={param.type}
                id={param.id}
                value={param.value}
                onChange={(e)=>parentmethod(e)}
                disabled={disabled} 
            />            
            <span 
                class="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"
            >
                {param.label}
            </span>
        </label>    
    )
}