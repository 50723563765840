import { useState } from "react"
import UnidadeForm from "../unidade/unidadeForm"
import LabelForm from "../auxiliares/labelForm"
import empreendimentoService from "../../services/empreendimentoService"
import {useNavigate} from 'react-router'
export default function EmpreendimentoForm(){
    //const [quantidade,set_quantidade] = useState(2)
    const navigate = useNavigate()
    const [valorUnidade,set_valor_unidade] = useState(350000)
    const [empreendimento,set_empreendimento] = useState({
        nome : '', 
        endereco : '', 
        bairro : '', 
        cidade : '', 
        estado : '',
        descricao:''
    })
    const [unidades, set_unidades] = useState([
    ])
    const [previsao_entrega,set_previsao_entrega] = useState(null)
    const [message_UI,set_message_UI] = useState('')
    const [processando,set_processando] = useState(false)
    
    function onChangeEmpreendimento(e){
        const {id,value} = e.target
        set_empreendimento({...empreendimento,[id]:value})
    }

    function onChangeUnidade(e,index){
        const {id,value} = e.target
        let arr_unidade = unidades.slice()
        arr_unidade[index][id] = value
        
        set_unidades(arr_unidade)
    }
    function listarUnidadeForm(quantidade) {
        let tempQuantidade = parseInt(quantidade)
        if(!isNaN(tempQuantidade)){
            set_unidades([...Array(parseInt(quantidade))].map((e,index)=>{return {descricao:'',valor:valorUnidade,numero:index}}))

            //return [...Array(parseInt(quantidade))].map((e,index)=><UnidadeForm numero={index} key={index}/>)
        }else{
            set_unidades([{descricao:'',valor:valorUnidade,numero:0}])

            //return <UnidadeForm numero={0} key={0}/>
        }
    }
    function cadastrar(e){
        e.preventDefault()
        
        let temp_emp = empreendimento
        temp_emp.unidades = unidades
        empreendimentoService.cadastrar(temp_emp)
        .then((res) => {
            const data = res.data;
            console.log(data)
            if (data.code ==200) {
                set_message_UI('Cadastro realizado com sucesso')
                navigate(0)
            }else{
                set_message_UI('Não foi possível realizar o cadastro'+data.message)
                set_processando(false)
            }

        })
        .catch((err) => {
            console.log(err)
            set_message_UI('Não foi possível realizar o cadastro')
            set_processando(false)
        }) 

    }
    return(
        <section className="bg-gray-100">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 " >
                <div className="grid grid-cols-2 gap-x-10 gap-y-8 lg:grid-cols-5">
                
                    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                        
                        <form action="" className="space-y-4">
                        <h2 class="text-2xl font-bold sm:text-3xl">Cadastro Empreendimento</h2>
                        
                        <div>
                            <LabelForm 
                                param={{label:'Nome',type:'text',value:empreendimento.nome,id:'nome'}} 
                                parentmethod={onChangeEmpreendimento}
                            />
                        </div>
                        <div>
                            <LabelForm 
                                param={{label:'Endereço',type:'text',value:empreendimento.endereco,id:'endereco'}} 
                                parentmethod={onChangeEmpreendimento}
                            />
                        </div>

                        <div>
                            <LabelForm 
                                param={{label:'Bairro',type:'text',value:empreendimento.bairro,id:'bairro'}} 
                                parentmethod={onChangeEmpreendimento}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <LabelForm 
                                    param={{label:'Cidade',type:'text',value:empreendimento.cidade,id:'cidade'}} 
                                    parentmethod={onChangeEmpreendimento}
                                />
                            </div>
                            <div>
                                <LabelForm 
                                    param={{label:'Estado',type:'text',value:empreendimento.estado,id:'estado'}} 
                                    parentmethod={onChangeEmpreendimento}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="sr-only" htmlFor="descricao">Descrição</label>

                            <textarea
                            className="w-full rounded-lg border-gray-200 p-3 text-sm"
                            placeholder="Descrição"
                            rows="4"
                            id="descricao"
                            value={empreendimento.descricao}
                            onChange={(e)=>onChangeEmpreendimento(e)} 
                            ></textarea>
                        </div>

                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <LabelForm 
                                    param={{label:'Quantidade de Unidades',type:'number',id:'quantidade_unidades'}} 
                                    parentmethod={(e)=>listarUnidadeForm(e.target.value)}
                                />
                            </div>

                            <div>
                                <LabelForm 
                                    param={{label:'Valor Médio de Unidade',type:'number',id:'valorUnidade',value:valorUnidade}} 
                                    parentmethod={(e)=>set_valor_unidade(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <LabelForm 
                                    param={{label:'Previsão de Entrega das Unidades',type:'date',id:'previsao_entrega',value:previsao_entrega}} 
                                    parentmethod={(e)=>set_previsao_entrega(e.target.value)}
                                />
                            </div>


                        </div>
                        <br/>
                        <div className="mt-4">
                                <button

                                className="inline-block w-full rounded-lg px-5 py-3 font-medium  sm:w-auto "
                                onClick={(e)=>cadastrar(e)}>
                                Cadastrar
                                </button>
                        </div>
                        </form>
                    </div>


                </div>
                <br/>
                <div className="grid grid-cols-4 gap-6" >  
                    
                    {unidades.map(unidade => {
                        return <UnidadeForm numero={unidade.numero} key={unidade.numero} 
                        valor={valorUnidade} previsao_entrega={previsao_entrega}
                        onChangeUnidadeParent={onChangeUnidade}/>
                    })}   
                    
                </div>
            </div>
        </section>
    )
}