import { useState } from "react"
export default function UnidadeForm ({numero,valor,onChangeUnidadeParent,previsao_entrega}){
    console.log(numero)
    console.log(previsao_entrega)
    const [unidade,set_unidade] = useState({
        valor:valor?valor:0.00,
        descricao:'',
        foto_capa:'',
        foto_planta:'',
        previsao_entrega:previsao_entrega?previsao_entrega:null
    })

    function onChangeUnidade(e){
        const {id,value} = e.target
        onChangeUnidadeParent(e,numero)
        set_unidade({...unidade,[id]:value})
    }
    return(
        <div className="w-full rounded-lg border-gray-200 p-3 text-sm bg-white" >
            <form action="" className="space-y-4">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                        <label
                        for="numero"
                        class="relative block overflow-hidden border-gray-200 pt-3"
                        >
                                Unidade {numero}
                        </label>    
                    </div>

                    <div></div>
                    
                    <div>
                        <label
                        for="valor"
                        class="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
                        >
                            <input
                                className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                placeholder="Valor"
                                type="number"
                                id="valor"
                                value={unidade.valor}
                                onChange={(e)=>onChangeUnidade(e)} 
                            />
                            
                            <span 
                                class="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"
                            >
                                Valor
                            </span>
                        </label>    
                    </div>

                    <div>
                            <label
                            for="previsao_entrega"
                            class="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
                            >
                                <input
                                    className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                    placeholder="previsao_entrega"
                                    type="date"
                                    id="previsao_entrega"
                                    value={unidade.previsao_entrega}
                                    onChange={(e)=>onChangeUnidade(e)} 
                                />
                                
                                <span 
                                    class="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"
                                >
                                    Previsão de Entrega
                                </span>
                            </label>    
                    </div>

                </div>
                
                <div>
                    <label htmlFor="message">Descrição</label>

                    <textarea
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Descrição"
                    rows="8"
                    id="descricao"
                    value={unidade.descricao}
                    onChange={(e)=>onChangeUnidade(e)}
                    ></textarea>
                </div>

            </form>

        </div>
    )
}