import { useState } from "react";

export default function Select({param,parentmethod,lista}){

    function removeDuplicadas(arr) {
        return [...new Set(arr)];
    }
    function formataLista(lista){
        const lista_iniciais = removeDuplicadas( lista.map(l=>{return l.nome[0]}) )
        const lista_final = lista_iniciais.map(li=>{
            return [li,lista.filter(l=>{return l.nome[0]==li})]
        })
        return lista_final  
    }

    return(
        <div>
            <label htmlFor="HeadlineAct" className="block text-sm font-medium text-gray-900"> {param.label} </label>

            <select
                name="HeadlineAct"
                id={param.id}
                className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
                onChange={(e)=>parentmethod(e)}
            >
                <option value="">Selecione um(a){param.label}</option>
                {formataLista(lista).map(
                    inicial=> <optgroup label={inicial[0]}>
                            {inicial[1].map(palavra=><option value={palavra.id}>{palavra.nome}</option>)}
                            
                        </optgroup>
                )}
            </select>
        </div>    
    )
}