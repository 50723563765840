import { useRef, useState,useEffect } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router'

import LabelForm from "../auxiliares/labelForm"
import LabelFormEdit from "../auxiliares/labelFormEdit"

import vendaService from "../../services/vendaService"
import Select from "../auxiliares/select"
import corretorService from "../../services/corretorService"
import ModalBase from "../auxiliares/modalBase"
//import UnidadeDetalhe from "../unidade/unidadeDetalhe"

export default function VendaDetalhe (){
    let navigate = useNavigate()
    let {id} = useParams()
    const [cliente,set_cliente] = useState({
        nome:'',
        sobrenome: '', 
        telefone: '', 
        email: '', 
        endereco: '', 
        bairro: '', 
        cidade: '', 
        estado: '', 
        profissao: '',
    })
    const [venda,set_venda] = useState(
        {
            id: 0,
            nome: "",
            endereco: "",
            bairro: "",
            cidade: "",
            estado: "",
            unidades:[]
        }
    )
    const [corretores,set_corretores] = useState([])
    const [status_lista,set_status_lista] = useState([])

    const [disableEdit,set_disableEdit] = useState(true)
    const [disableStatusChange,set_disableStatusChange] = useState(true)
    const disableCorretorChange = useRef(false)
    const novo_status = useRef('')
    const [modal_status,set_modal_status] = useState(false)
    
    const [message_UI,set_message_UI] = useState('')

    useEffect(
        ()=>{
        vendaService.detalhe(id)
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_venda(data);
        })
        .catch((err) => console.log(err))

        vendaService.listaStatus()
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_status_lista(data);
        })
        .catch((err) => console.log(err))


        corretorService.lista()
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_corretores(data[0]);
        })
        .catch((err) => console.log(err))
    },[])

    function onChangeVenda(e){
        const {id,value} = e.target
        set_venda({...venda,[id]:value})
    }
    function onChangeStatus(e){
        const {id,value} = e.target
        novo_status.current = value
        console.log(novo_status.current)
    }

    function toggleEdit(){
        set_disableEdit(!disableEdit)
    }

    function toggleStatusChange(){
        set_disableStatusChange(!disableStatusChange)
    }

    function toggleCorretorChange(){
        disableCorretorChange.current = !disableCorretorChange.current 
    }

    function atualizarVenda(){
        toggleEdit()
        vendaService.atualizar(venda)
        .then(res=>{
            console.log(res)
            navigate(0)
        }).catch(err=>{
            console.error(err)
            set_message_UI('Não foi possível atualizar. '+err?.response?.data?.message)
        })
    }

    function atualizarStatus(){
        if (novo_status.current !=='') {
            toggleStatusChange()
            vendaService.atualizarStatus(venda.id,novo_status.current)
            .then(res=>{
                console.log(res)
                navigate(0)
            }).catch(err=>{
                console.error(err)
                set_message_UI('Não foi atualizar. '+err?.response?.data?.message)
            })            
        }else{
            set_message_UI('Selecione um status da listagem')
            //alert('selecione um status da listagem')
        }

    }

    function cancelarReserva(){
        vendaService.cancelarReserva(venda.id)
        .then(res=>{
            console.log(res)
            navigate(0)
        }).catch(err=>{
            console.error(err)
            set_message_UI('Não foi possível cancelar. '+err?.response?.data?.message)
        })            

    }
    const abrirModal = () => {
        console.log('abrir')
        set_modal_status(true);
    };

    const fecharModal = () => {
        console.log('fechar')
        set_modal_status(false);
    };

    return(
        <section className="bg-gray-100">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8" >
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 ">
                    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                        <form onSubmit={(e)=>e.preventDefault()} className="space-y-4">
                            
                            <h2 class="text-2xl font-bold sm:text-3xl">Venda - {id}</h2>

                            <div>
                                <div>
                                    <LabelFormEdit
                                    param={{label:'Status',type:'text',value:venda.status?.descricao,id:'status'}} 
                                    disabled={true}/>
                                </div>
                                
                                <div  hidden={disableStatusChange}>
                                        
                                    <div>
                                        <label htmlFor="HeadlineAct" className="block text-sm font-medium text-gray-900"> Status </label>

                                        <select
                                            name="HeadlineAct"
                                            id={99}
                                            className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
                                            onChange={(e)=>onChangeStatus(e)}
                                        >
                                            <option value="">Selecione o novo Status</option>
                                            {status_lista.map(
                                                status=> <option value={status.id}>{status.descricao}</option>
                                            )}
                                        </select>
                                    </div>  
                                    
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Empreendimento',type:'text',value:venda.unidade?.empreendimento.nome,id:'empreendimento'}} 
                                    disabled={true}/>
                                   
                                </div>

                                <div>
                                    <LabelFormEdit
                                    param={{label:'N° Unidade',type:'number',value:venda.unidade?.numero,id:'valor'}} 
                                    disabled={true}/>                                   
                                </div>

                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Corretor',type:'text',value:venda.corretor?.nome,id:'empreendimento'}} 
                                    disabled={true}/>
                                   
                                </div>

                                <div hidden>
                                    <Select 
                                    param={{label:'Corretor',id:'corretor'}}
                                    lista={corretores}
                                    />
                                </div>

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Cliente',type:'text',value:venda.cliente?.nome,id:'valor'}} 
                                    disabled={true}/>                                   
                                </div>

                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Valor',type:'number',value:venda.valor,id:'valor'}}
                                    parentmethod={onChangeVenda} 
                                    disabled={disableEdit}/>
                                   
                                </div>

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Valor Sinal',type:'number',value:venda.valor_sinal,id:'valor_sinal'}} 
                                    parentmethod={onChangeVenda}
                                    disabled={disableEdit}/>                                   
                                </div>

                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Complemento Entrada',type:'number',value:venda.valor_complemento_entrada,id:'valor_complemento_entrada'}} 
                                    parentmethod={onChangeVenda}
                                    disabled={disableEdit}/>                               
                                </div>

                                <div>
                                    <LabelFormEdit
                                    param={{label:'Débito',type:'number',value:venda.valor_debito,id:'valor_debito'}} 
                                    parentmethod={onChangeVenda}
                                    disabled={disableEdit}/>                               
                                </div>

                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <button onClick={abrirModal} className='border-solid border-indigo-600 border-2 rounded'>  
                                        Cancelar Reserva
                                    </button>
                                </div>

                                
                                <div>
                                    {disableEdit?
                                        <button className='border-solid border-indigo-600 border-2 rounded'
                                        onClick={()=>toggleEdit()}
                                        >  
                                            Atualizar Venda
                                        </button>
                                    :
                                        <button className='border-solid border-indigo-600 border-2 rounded'
                                        onClick={()=>atualizarVenda()}
                                        >  
                                            Confirma atualização
                                        </button>
                                    }

                                </div>

                                <div>
                                    {disableStatusChange?
                                        <button className='border-solid border-indigo-600 border-2 rounded'
                                        onClick={()=>toggleStatusChange()}
                                        >  
                                            Atualizar Status
                                        </button>
                                    :
                                        <button className='border-solid border-indigo-600 border-2 rounded'
                                        onClick={()=>atualizarStatus()}
                                        >  
                                            Confirma atualização de Status
                                        </button>
                                    }

                                </div>

                            </div>

                        </form>
                    </div>
                </div>

            </div>
            <ModalBase isOpen={modal_status} onClose={fecharModal}>
                <section className="bg-gray-100">
                    <h2>Cancelar Reserva</h2>
                    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8" >
                        <div>
                            <button onClick={()=>cancelarReserva()} className='border-solid border-indigo-600 border-2 rounded'>
                                Confirmar cancelamento
                            </button>
                        </div>
                        <br/>
                        <div>
                            <button onClick={fecharModal} className='border-solid border-indigo-600 border-2 rounded'>
                                Voltar
                            </button>
                        </div>
                    </div>
                </section>
            </ModalBase>
            <div>
                {message_UI}
            </div>
        </section>
    )
}