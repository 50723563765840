import {Link} from 'react-router-dom'
export default function EmpreendimentoCard({empreendimento}){

    return(
        <Link to={`/empreendimento/${empreendimento.id}`}>
        <a href="#" className="group block overflow-hidden" >
        <img
            src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt=""
            className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
        />

        <div className="relative bg-white pt-3">
            <h3
            className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4"
            >
            {empreendimento.nome}
            </h3>

            <p className="mt-2">
            <span className="sr-only"> {empreendimento.bairro} - {empreendimento.cidade} </span>

            <span className="tracking-wider text-gray-900"> {empreendimento.valor} </span>
            </p>
        </div>
        </a>
        </Link>
    )
}