export default function ModalBase({ isOpen, onClose, children }){
    const modalClasses = isOpen ? 'fixed top-1/4 right-1/6 bottom-1/4 left-1/4 flex items-center justify-center' : 'hidden';
    const overlayClasses = isOpen ? 'fixed top-1/3 right-1/3 bottom-1/3 left-1/3 bg-black opacity-50 z-3' : 'hidden';
    const modalContentClasses = isOpen ? 'bg-white p-6 rounded shadow-md z-5' : 'hidden';  

    return (
        <div className={modalClasses}>
            {/*<div className={overlayClasses}></div>*/}
            <div className={modalContentClasses}>
                <button className="absolute botton-2 right-6 text-gray-600 border-solid border-indigo-600 border-2 rounded" onClick={onClose}>
                Fechar
                </button>
                {children}
            </div>
        </div>
    );
}