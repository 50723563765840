import { useState,useEffect, useRef } from "react"
import LabelForm from "../auxiliares/labelForm"
import Select from "../auxiliares/select"
import corretorService from "../../services/corretorService"
import clienteService from "../../services/clienteService"
import vendaService from "../../services/vendaService"
import { validarObjeto } from "../utils/utilitarios"
import { Link, Navigate, redirect,  } from "react-router-dom"
import {useNavigate} from 'react-router'
import lodash from 'lodash'

export default function VendaForm ({unidade}){
    const navigate = useNavigate()
    const [venda,set_venda] = useState({
        valor : unidade.valor,
        valor_sinal: 0, 
        valor_complemento_entrada : 0, 
        valor_debito : 0,
        unidade: unidade?unidade.id: 1,
        cliente : 0,
        corretor : 0 ,
        status: 1
        
    })
    const [corretores,set_corretores] = useState([])
    const [clientes,set_clientes] = useState([])
    const cliente_busca = useRef('')
    const [buscando,set_buscando] = useState(true)
    const [message_UI,set_message_UI] = useState('')
    const [processando,set_processando] = useState(false)
    const oferta_cadastro_cliente = useRef(false)

    useEffect(
        ()=>{
        corretorService.lista()
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_corretores(data[0]);
        })
        .catch((err) => console.log(err))
    },[])

    function onChangeVenda(e){
        const {id,value} = e.target
        set_venda({...venda,[id]:value})
    }
    
    async function buscarCliente(e){
        set_buscando(true)
        const {id,value} = e.target

        if (value.length > 2 && value!==cliente_busca.current) {
            
            cliente_busca.current = value
            //set_cliente_busca({...venda,[id]:value})
            clienteService.listaPornome(value)
            .then((res) => {
                const data = res.data;
                console.log(data)
                console.log(oferta_cadastro_cliente.current)
                set_clientes(data)
                if(data.length>0){
                    oferta_cadastro_cliente.current = false
                }else{
                    oferta_cadastro_cliente.current = true
                }
                set_buscando(false)
            })
            .catch((err) => console.log(err))  
        }else{
            oferta_cadastro_cliente.current = false
        }
        //set_buscando(false)
    }

    const debounceBuscarCliente = lodash.debounce(buscarCliente,400)

    async function cadastrar(){
        console.log(venda)
        set_processando(true)
        
        if (validarObjeto(venda,set_message_UI)) {
            await vendaService.cadastrar(venda)
            .then((res) => {
                    const data = res.data;
                    console.log(res)
                    console.log(data)
                    if (res.status ==201) {
                        set_message_UI('Cadastro realizado com sucesso')
                        navigate(0)
                    }else{
                        set_message_UI('Não foi possível realizar o cadastro.'+data.message)
                    }
                })
            .catch((err) => {
                console.log(err)
                set_message_UI(`Não foi possível realizar o cadastro.
                        ${err?.response?.data?.message}
                `)
            })    
        }
        set_processando(false)

    }
    return(
        <section className="bg-gray-100">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8" >
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                        <form onSubmit={(e)=>{e.preventDefault()}} className="space-y-4">
                            
                            <h2 class="text-2xl font-bold sm:text-3xl">Ven {unidade?.id}</h2>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div>
                                    <LabelForm 
                                    param={{label:'Valor',type:'number',value:venda.valor,id:'valor'}} 
                                    parentmethod={onChangeVenda}/>
                                    
                                </div>   

                                <div>
                                    <LabelForm 
                                    param={{label:'Valor Sinal',type:'number',value:venda.valor_sinal,id:'valor_sinal'}} 
                                    parentmethod={onChangeVenda}/>
                                </div>       
                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div>
                                    <LabelForm 
                                    param={{label:'Valor complemento de entrada',type:'number',value:venda.valor_complemento_entrada,id:'valor_complemento_entrada'}} 
                                    parentmethod={onChangeVenda}/>
                                </div>                                   
                                <div>
                                    <LabelForm 
                                    param={{label:'Valor Débito',type:'number',value:venda.valor_debito,id:'valor_debito'}} 
                                    parentmethod={onChangeVenda}/>
                                </div>                                   
                            </div>

                            
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <div>
                                        <LabelForm 
                                        param={{label:'Buscar Cliente',type:'text',id:'valor_debito'}} 
                                        parentmethod={debounceBuscarCliente}/>
                                    </div>  
                                </div>

                                {
                                    buscando
                                    ?
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                    </div>
                                    :<div>
                                        {oferta_cadastro_cliente.current ===false ?        
                                            <div>
                                                <Select 
                                                param={{label:'Cliente',id:'cliente'}}
                                                lista={clientes}
                                                parentmethod={onChangeVenda}/>
                                            </div>
                                            :<p>Cliente não encontrado</p>
                                        }
                                    </div>
                                }
                            </div>

                            {oferta_cadastro_cliente.current ===true ?
                            <button className='border-solid border-indigo-600 border-2 rounded'>                        
                                <Link to={`/cliente/cadastrar`}> Cadastrar novo cliente.</Link>
                            </button>
                            :
                            null}
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">


                                <div>
                                    <Select 
                                    param={{label:'Corretor',id:'corretor'}}
                                    lista={corretores}
                                    parentmethod={onChangeVenda}/>
                                </div>
                            </div>

                            {processando
                                    ?
                                    <div>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>

                                    </div>
                                    :<button onClick={cadastrar} className='border-solid border-indigo-600 border-2 rounded'>Cadastrar</button>}

                                    <p>{message_UI}</p>
                        </form>

                    </div>
                </div>
            </div>
        </section>
    )
}