import axios from 'axios';
import config from '../config'
//import authHeader from './auth-header.service.js';

const API_URL = config.OriginPath+'/empreendimentos/';

class EmpreendimentoService {
  detalhe(id) {
    return axios.get(API_URL + `${id}`,{});
  }

  lista(page) {
    return axios.get(API_URL +`page/${page}`,{});
  }

  cadastrar(empreendimento) {
    return axios.post(API_URL ,empreendimento);
  }
}

export default new EmpreendimentoService();