import axios from 'axios';
import config from '../config'
//import authHeader from './auth-header.service.js';

const API_URL = config.OriginPath+'/cliente/';

class ClienteService {
  detalhe(id) {
    return axios.get(API_URL + `${id}`,{});
  }

  lista() {
    return axios.get(API_URL+``,{});
  }
  
  listaPornome(nome) {
    return axios.get(API_URL+`nome/${nome}`,{});
  }

  cadastrar(cliente) {
    return axios.post(API_URL ,cliente);
  }
}

export default new ClienteService();