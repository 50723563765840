import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Contact from "./routes/contact";
import EmpreendimentoForm from "./components/empreendimentos/empreendimentoForm";
import ClienteForm from "./components/clienteForm";
import CorretorForm from "./components/corretorForm";
import EmpreendimentoLista from "./components/empreendimentos/empreendimentoLista";
import EmpreendimentoDetalhe from "./components/empreendimentos/empreendimentoDetalhe";
import VendaForm from "./components/venda/vendaForm";
import VendaLista from "./components/venda/vendaLista";
import VendaDetalhe from "./components/venda/vendaDetalhe";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "empreendimento/cadastrar",
        element: <EmpreendimentoForm />,
      },
      {
        path: "cliente/cadastrar",
        element: <ClienteForm />,
      },
      {
        path: "corretor/cadastrar",
        element: <CorretorForm />,
      },
      {
        path: "empreendimento/lista",
        element: <EmpreendimentoLista />,
      },
      {
        path: "empreendimento/:id",
        element: <EmpreendimentoDetalhe />,
      },
      {
        path: "venda",
        element: <VendaForm />,
      },
      {
        path: "venda/:id",
        element: <VendaDetalhe />,
      },
      {
        path: "venda/lista",
        element: <VendaLista />,
      },
    ],
  },

]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);