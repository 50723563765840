import { useEffect, useRef, useState } from "react"
import { BSuccess,BDanger,BWarning } from "../auxiliares/badges"
import ModalBase from "../auxiliares/modalBase"
import VendaForm from "../venda/vendaForm"
import { Link } from "react-router-dom"
export default function UnidadeDetalhe ({parentUnidade}){
    
    const [unidade,set_unidade] = useState(parentUnidade?parentUnidade:{id:0})
    const [modal_status,set_modal_status] =  useState(false)
    const status_badge = useRef(<BSuccess message={'Disponivel'}/>)
    const reservado = useRef(false)
    const id_reserva = useRef(0)
    
    useEffect(()=>{
        verificaDisponibilidade()
    },[])

    function verificaDisponibilidade(){
        console.log('verifica')
        let disponibilidade = <BSuccess message={'Disponivel'}/>
        const venda_reserva = unidade.vendas.find((e)=>e.status.id == 1)
        const venda_andamento = unidade.vendas.find((e)=>e.status.id != 1 && e.status.id != 8 && e.status.id != 9)
        if (unidade.vendas && venda_reserva) {
            disponibilidade =<BWarning message={'Reservado'}/>
            reservado.current = true
            id_reserva.current = venda_reserva.id
        }

        if (unidade.vendas && venda_andamento ) {
            disponibilidade = <BDanger message={'Venda em andamento'}/>            
            id_reserva.current = venda_andamento.id
        }
        status_badge.current= disponibilidade
    }

    function cancelarReserva (){
        
    }

    const abrirModal = () => {
        console.log('abrir')
        set_modal_status(true);
    };

    const fecharModal = () => {
        console.log('fechar')
        set_modal_status(false);
    };

    function onChangeUnidade(e){
        const {id,value} = e.target
        set_unidade({...unidade,[id]:value})
    }
    return(
    <>        
        <a onClick={(e)=>e.preventDefault()} href="#" className="block rounded-lg p-4 shadow-sm shadow-indigo-100">
            <img
                alt="Image"
                src="https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                className="h-56 w-full rounded-md object-cover"
            />

            <div className="mt-2">
                <dl>
                <div>
                    <dt className="sr-only">Valor</dt>

                    <dd className="text-sm text-gray-500">R${unidade.valor}</dd>
                </div>

                <div>
                    <dt className="sr-only">Disponibilidade</dt>

                    <dd className="font-medium">{status_badge.current} </dd>
                </div>
                </dl>

                <div className="mt-6 flex items-center gap-8 text-xs">
                <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                    <svg
                    className="h-4 w-4 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                    />
                    </svg>

                    <div className="mt-1.5 sm:mt-0">
                    <p className="text-gray-500">Garagem</p>

                    <p className="font-medium">2 Vagas</p>
                    </div>
                </div>

                <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                    <svg
                    className="h-4 w-4 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                    </svg>

                    <div className="mt-1.5 sm:mt-0">
                    <p className="text-gray-500">Quartos</p>

                    <p className="font-medium">2 Suítes</p>
                    </div>
                </div>

                <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                    <svg
                    className="h-4 w-4 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                    />
                    </svg>

                    <div className="mt-1.5 sm:mt-0">
                    <p className="text-gray-500">Banheiros</p>

                    <p className="font-medium">4 Banheiros</p>
                    </div>
                </div>
                </div>
            </div>

            
            {reservado.current?
            <Link to={`/venda/${id_reserva.current}`}>
                <button className='border-solid border-indigo-600 border-2 rounded'>Detalhe da Venda</button>
            </Link>
            :<button onClick={abrirModal} className='border-solid border-indigo-600 border-2 rounded'> 
                Vender
            </button>
            }
            <ModalBase isOpen={modal_status} onClose={fecharModal}>
                <VendaForm unidade={unidade}></VendaForm>
            </ModalBase>
        </a>

    </>
    )
}