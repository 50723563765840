import { Link, Outlet } from "react-router-dom";

export default function SideBar(){
    
    return (
    <div className="flex-none h-screen flex-col justify-between border-e bg-white">
        <div className="px-4 py-6">
            <span className="grid h-10 w-32 place-content-center rounded-lg bg-gray-100 text-xs text-gray-600">
            -
            </span>

            <ul className="mt-6 space-y-1">
            <li>
                <a  className="block rounded-lg bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
                    Início
                </a>
            </li>

            <li>
                <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary
                    className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                >
                    <span className="text-sm font-medium"> Cadastros </span>

                    <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                        />
                    </svg>
                    </span>
                </summary>

                <ul className="mt-2 space-y-1 px-4">
                    <li>
                        <Link to={'empreendimento/cadastrar'}>
                            <a
                                
                                className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                            >
                                Empreendimento
                            </a>
                        </Link>
                    </li>

                    <li>
                        <Link to={`cliente/cadastrar`}>
                            <a
                                
                                className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                            >
                                Cliente
                            </a>
                        </Link> 
                    </li>
                        <li>
                        <Link to={`corretor/cadastrar`}>
                            <a
                                className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                            >
                                Corretor
                            </a>
                        </Link> 
                    </li>
                </ul>
                </details>
            </li>

            <li>
                <Link to={'empreendimento/lista'}>
                    <a
                    
                    className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                    >
                    Empreendimentos
                    </a>
                </Link>
            </li>

            <li>
                <Link to={'venda/lista'}>
                    <a
                    
                    className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                    >
                    Vendas
                    </a>
                </Link>
            </li>

            </ul>
        </div>

        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
            <a  className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">

            </a>
        </div>
    </div>
    )
}