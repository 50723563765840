export function validarObjeto(objeto,callbackMessage) {
    for (let chave in objeto) {
        if (objeto.hasOwnProperty(chave)) {
            console.log(chave)
            console.log(typeof chave)
        // Verifica se o campo está definido e não é nulo
        if (objeto[chave] === undefined || objeto[chave] === null|| objeto[chave]=== '' || objeto[chave]=== 0 ) {
            callbackMessage(`Preencha o campo ${chave.toUpperCase()} corretamente`)
            return false;
        }
        }
    }
    return true;
}
