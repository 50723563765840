import { useState } from "react"
import LabelForm from "./auxiliares/labelForm"
import corretorService from "../services/corretorService"
import { validarObjeto } from "./utils/utilitarios"
import {useNavigate} from 'react-router'
export default function CorretorForm (){
    const navigate = useNavigate()
    const [corretor,set_corretor] = useState({
        nome:'',
        sobrenome: '', 
        telefone: '', 
        email: '', 
        endereco: '', 
        bairro: '', 
        cidade: '', 
        estado: '', 
        imobiliaria: '',
    })
    const [message_UI,set_message_UI] = useState('')
    const [processando,set_processando] = useState(false)

    function onChangeCorretor(e){
        const {id,value} = e.target
        set_corretor({...corretor,[id]:value})
    }

      
    async function cadastrar(){
        console.log(corretor)
        set_processando(true)
        
        if (validarObjeto(corretor,set_message_UI)) {
            await corretorService.cadastrar(corretor)
            .then((res) => {
                    const data = res.data;
                    console.log(data)
                    if (data.code ==200) {
                        set_message_UI('Cadastro realizado com sucesso')
                        navigate(0)
                    }else{
                        set_message_UI('Não foi possível realizar o cadastro'+data.message)
                        set_processando(false)
                    }

                })
            .catch((err) => {
                console.log(err)
                set_message_UI('Não foi possível realizar o cadastro')
                set_processando(false)
            })    
        }else{
            set_processando(false)
        }

    }

    return(
        <section className="bg-gray-100">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8" >
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                        <form onSubmit={(e)=>e.preventDefault()} className="space-y-4">
                            
                            <h2 class="text-2xl font-bold sm:text-3xl">Cadastro Corretor</h2>
                            <div>

                                <div>
                                    <LabelForm 
                                    param={{label:'Nome',type:'text',value:corretor.nome,id:'nome'}} 
                                    parentmethod={onChangeCorretor}/>
                                    
                                </div>

                            </div>
                            
                            <div>

                                <div>
                                    <LabelForm 
                                    param={{label:'Sobrenome',type:'text',value:corretor.sobrenome,id:'sobrenome'}} 
                                    parentmethod={onChangeCorretor}/>
                                   
                                </div>

                            </div>


                            <div>

                                <div>
                                    <LabelForm 
                                    param={{label:'Imobiliária',type:'text',value:corretor.imobiliaria,id:'imobiliaria'}} 
                                    parentmethod={onChangeCorretor}/>
                                      
                                </div>

                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelForm 
                                    param={{label:'Telefone',type:'tel',value:corretor.telefone,id:'telefone'}} 
                                    parentmethod={onChangeCorretor}/>
                                       
                                </div>


                                <div>
                                    <LabelForm 
                                    param={{label:'Email',type:'email',value:corretor.email,id:'email'}} 
                                    parentmethod={onChangeCorretor}/>
                                     
                                </div>

                            </div>

                            <div>
                                <div>
                                <LabelForm 
                                    param={{label:'Endereço',type:'text',value:corretor.endereco,id:'endereco'}} 
                                    parentmethod={onChangeCorretor}/>
                                    
                                </div>

                            </div>


                            <div>

                                <div>
                                    <LabelForm 
                                    param={{label:'Bairro',type:'text',value:corretor.bairro,id:'bairro'}} 
                                    parentmethod={onChangeCorretor}/>
                                     
                                </div>

                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div>
                                    <LabelForm 
                                    param={{label:'Estado',type:'text',value:corretor.estado,id:'estado'}} 
                                    parentmethod={onChangeCorretor}/>
                                      
                                </div>


                                <div>
                                    <LabelForm 
                                    param={{label:'Cidade',type:'text',value:corretor.cidade,id:'cidade'}} 
                                    parentmethod={onChangeCorretor}/>
                                      
                                </div>

                            </div>
                                    {processando
                                    ?
                                    <div>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>

                                    </div>
                                    :<button onClick={cadastrar}>Cadastrar</button>}

                                    <p>{message_UI}</p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}