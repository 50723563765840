import axios from 'axios';
import config from '../config'
//import authHeader from './auth-header.service.js';

const API_URL = config.OriginPath+'/venda/';

class VendaService {
  detalhe(id) {
    return axios.get(API_URL + `${id}`,{});
  }
  lista(page) {
    return axios.get(API_URL+`all/${page}`,{});
  }
  
  listaStatus() {
    return axios.get(API_URL+`status/all`,{});
  }
  cadastrar(venda) {
    return axios.post(API_URL ,venda);
  }
  atualizar(venda) {
    return axios.patch(API_URL+venda.id ,venda);
  }
  atualizarStatus(id,status) {
    return axios.patch(API_URL+'status/'+id ,{status:status});
  }
  cancelarReserva(id) {
    return axios.patch(API_URL+`cancel/${id}`);
  }
  cancelar(id) {
    return axios.patch(API_URL+`cancel/bill/${id}`);
  }
  deletar(id) {
    return axios.delete(API_URL+`${id}`);
  }
}

export default new VendaService();