import { useEffect, useRef, useState } from "react"
import EmpreendimentoCard from "./empreendimentoCard"
import empreendimentoService from "../../services/empreendimentoService"
import Pagination from "../auxiliares/pagination"

export default function EmpreendimentoLista() {
    const [empreendimentos,set_empreendimentos] = useState([])
    const [total,set_total] = useState(0)
    const [pagina,set_pagina] = useState(1)
    const [paginas,set_paginas] = useState([1])
    const empRef = useRef()
    
    useEffect(
        ()=>{
        empreendimentoService.lista(1)
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_empreendimentos(data[0]);
            set_paginas(Math.ceil(data[1]/8))
        })
        .catch((err) => console.log(err))
    },[])

    function passarPagina (pagina,tipo){
        buscaPorPagina(pagina)
    }

    function buscaPorPagina(pagina){
        empreendimentoService.lista(pagina)
        .then((res) => {
            const data = res.data;
            console.log(data)
            set_empreendimentos(data[0]);
            set_pagina(pagina)
        })
        .catch((err) => console.log(err))
    }


    return(
    <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 " >
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 bg-white" >
                <header>
                <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">Empreendimentos</h2>

                <p className="mt-4 max-w-md text-gray-500">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque praesentium cumque iure
                    dicta incidunt est ipsam, officia dolor fugit natus?
                </p>
                </header>

                <div className="mt-8 block lg:hidden">
                <button
                    className="flex cursor-pointer items-center gap-2 border-b border-gray-400 pb-1 text-gray-900 transition hover:border-gray-600"
                >
                    <span className="text-sm font-medium"> Filters & Sorting </span>

                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-4 w-4 rtl:rotate-180"
                    >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
                </div>

                <div className="mt-4 lg:mt-8 lg:grid lg:grid-cols-4 lg:items-start lg:gap-8">

                <div className="lg:col-span-4">
                    <ul className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
                        
                        {empreendimentos.map(empreendimento=><li><EmpreendimentoCard empreendimento={empreendimento}/></li>)}
                        
                    </ul>
                </div>
                </div>
                <Pagination param={{paginas:paginas,pagina:pagina}} parentmethod={passarPagina}></Pagination>
            </div>
        </div>
        </section>
    )
}